<!--<h1 mat-dialog-title>{{T.F.SYNC.D_CONFLICT.TITLE|translate}}</h1>-->
<h1 mat-dialog-title>Configure Sync</h1>

<mat-dialog-content>
  <form [formGroup]="form">
    <formly-form
      (modelChange)="updateCfg($event)"
      [fields]="fields"
      [form]="form"
      [model]="cfg"
    >
    </formly-form>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="wrap-buttons">
    <button
      (click)="close()"
      color="primary"
      mat-button
    >
      {{T.G.CANCEL|translate}}
    </button>
    <button
      [disabled]="!form.valid"
      (click)="save()"
      color="primary"
      mat-stroked-button
    >
      <mat-icon>save</mat-icon>
      {{T.G.SAVE|translate}}
    </button>
  </div>
</mat-dialog-actions>
